import { createContext } from "react";
import { ReplaySubject, Observable } from "rxjs";

export const NavigationServiceContext = createContext<NavigationService>(null!);

export enum Page {
  Loading,
  Spots,
  AddSpot,
  Profile,
}

export class NavigationService {
  private readonly currentPage = new ReplaySubject<Page>(1);

  constructor() {
    this.currentPage.next(Page.Spots);
  }

  getCurrentPage(): Observable<Page> {
    return this.currentPage.asObservable();
  }

  openPage(page: Page) {
    this.currentPage.next(page);
  }
}
