
import { BottomNavigation, BottomNavigationAction, Toolbar, AppBar, Button, Icon } from '@mui/material';
import { Add, Person } from '@mui/icons-material';

import React, { useContext, useEffect, useState } from 'react';
import logoSmall from 'assets/logo192.png';
import './RootView.css';
import { NavigationServiceContext, Page } from 'service/NavigationService';
import AddSpot from './addspot/AddSpot';
import Spots from './spots/Spots';
import Profile from './profile/Profile';
import Loading from './loading/Loading';

function renderPage_(page: Page) {
  switch (page) {
    case Page.Loading:
      return <Loading></Loading>;
    case Page.AddSpot:
      return <AddSpot></AddSpot>;
    case Page.Spots:
      return <Spots></Spots>;
    case Page.Profile:
      return <Profile></Profile>;
    default:
      return <Loading></Loading>;
  }
}

function RootView() {
  const [page, setPage] = useState<Page>(Page.Loading);
  const navigationService = useContext(NavigationServiceContext);

  useEffect(() => {
    const pageSub = navigationService.getCurrentPage().subscribe((page) => setPage(page));
    return () => {
      pageSub.unsubscribe();
    };
  });

  return (
    <div className="RootView">
      <AppBar position="static" className="TopBar">
        <Toolbar className="TopBarContent">
          <img src={logoSmall} className="TopBarLogo" alt="logo" />
          <div className="TopBarButtons">
            <Button component="div" onClick={() => navigationService.openPage(Page.Spots)}>
              Spots
            </Button>
            <Button component="div" onClick={() => navigationService.openPage(Page.AddSpot)}>
              Add spot
            </Button>
            <Button component="div" onClick={() => navigationService.openPage(Page.Profile)}>
              Profile
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <div className="Content">
        {renderPage_(page)}
      </div>

      <BottomNavigation showLabels className="BottomBar" value={page}
        onChange={(evt, newPage: Page) => navigationService.openPage(newPage)}>
        <BottomNavigationAction label="Spots" value={Page.Spots} icon={<Icon className="kitesurf-icon">&#xe800;</Icon>} />
        <BottomNavigationAction label="Add spot" value={Page.AddSpot} icon={<Add />} />
        <BottomNavigationAction label="Profile" value={Page.Profile} icon={<Person />} />
      </BottomNavigation>
    </div>
  );
}

export default RootView;
