import React from 'react';
import logo from 'assets/logo512.png';

function Profile() {
  return (
    <div className="Profile">
      Profile

      <img src={logo} className="App-logo" alt="logo" />
    </div>
  );
}

export default Profile;
