import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'fonts/kitesurf.ttf';

import React from 'react';
import './App.css';
import RootView from 'views/RootView';

function App() {
  return (
    <div className="App">
      <RootView></RootView>
    </div>
  );
}

export default App;
