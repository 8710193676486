import React from 'react';
import logo from 'assets/logo512.png';
import { LinearProgress } from '@mui/material';

import './Loading.css';

function Loading() {
  return (
    <div className="Loading">
      <LinearProgress className="LoadingProgress"></LinearProgress>
      <div className="LoadingLogo" >
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
}

export default Loading;
