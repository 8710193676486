import React from 'react';
import logo from 'assets/logo512.png';

function AddSpot() {
return (
<div className="AddSpot">
Add Spot

<img src={logo} className="App-logo" alt="logo" />
</div>
);
}

export default AddSpot;
