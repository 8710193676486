import React from 'react';
import logo from 'assets/logo512.png';

function Spots() {
  return (
    <div className="Spots">
      Spots

      <img src={logo} className="App-logo" alt="logo" />
    </div>
  );
}

export default Spots;
